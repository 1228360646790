export const primaryFont = "'Montserrat', sans-serif";
export const secondaryFont = "'Ubuntu', sans-serif";


export const typeScale = {
    66: '4.125rem',
    51: '3.1875rem',
    50: '3.125rem',
    40: '2.5rem',
    32: '2rem',
    30: '1.75rem',
    28: '1.625rem',
    26: '1.5rem',
    24: '1.375rem',
    22: '1.25rem',
    20: '1.15rem',
    17: '1.0625rem',
    16: '1rem',
    headerOne: '4.209rem',
    headerTwo: '3.157rem',
    headerThree: '2.369rem',
    headerFour: '1.777rem',
    headerFive: '1.333rem',
    paragraph: '1rem',
    small: '0.75rem',
    smallest: '0.563rem',
}
