const initialState = {
    registrationError: null,
    registrationSuccess: null,
    isRegistering: false,
}

const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REGISTER_USER':
            console.log('registered user');
            return {...state, registrationError: null, registrationSuccess: 'successful registration', isRegistering: false};
        case 'REGISTER_USER_ERR':
            console.log('register user failed', action.err);
            return {...state, registrationError: 'Something went wrong, Please try again', registrationSuccess: null};
        case 'REGISTER_START':
            return {...state, isRegistering: true, registrationSuccess: false, registrationError: false}
        case 'REMOVE_REGISTER':
            return {...state, registrationSuccess: null, registrationError: null, isRegistering: false};
        default:
            return state
    }
}

export default registerReducer;
