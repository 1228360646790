import styled from "styled-components";

export const LogoOneWrapper = styled.svg`
   .a{
  fill:url(#a);
  }
  .b
  {fill:url(#b);
  }
  .c{
  fill:url(#c);
  }
`;

export const TwitterWrapper = styled.svg`
  .a{fill:#fff;}
`;
